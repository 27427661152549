import React, { useState } from "react"
import Layout from "../components/layout.js"
import Seo from "../components/seo"
import { graphql } from "gatsby"
//import FileUpload from "../components/fileUpload.js"
import { HashLoader } from "react-spinners"
const backend = require("../helpers/devsurvival.api.js")

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    bio: "",
    topics: [],
    website: "",
    note: "",
    title: "",
    content: "",
    //files: [],
  })
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState("")
  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const submitBlog = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      await backend.submitBlog(formState)
      setNotification("blog submitted!")
    } catch (err) {
      setNotification("sorry, server error. Please try again later")
    }

    setTimeout(() => setLoading(false), 500)
  }

  //   const fileUpload = files => {
  //     setFormState({ ...formState, files })
  //   }

  //   const removeFile = file => {
  //     setFormState({
  //       ...formState,
  //       files: formState.files.filter(f => f.path !== file.path),
  //     })
  //   }

  const {
    firstName,
    lastName,
    email,
    bio,
    topics,
    note,
    title,
    website,
    content,
  } = formState
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Submit Guest Blog" />
      <h4>Submit Guest Blog</h4>
      {loading ? (
        <HashLoader />
      ) : (
        <form onSubmit={submitBlog} className="contact-form">
          <p>{notification}</p>
          <input
            type="text"
            name="firstName"
            value={firstName}
            onChange={onChange}
            placeholder="First Name*"
            required
          />
          <input
            type="text"
            name="lastName"
            value={lastName}
            onChange={onChange}
            placeholder="Last Name*"
            required
          />
          <input
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            placeholder="Email*"
            required
          />
          <input
            type="text"
            name="bio"
            value={bio}
            onChange={onChange}
            placeholder="Tell us about yourself*"
            required
          />
          <input
            type="text"
            name="website"
            value={website}
            onChange={onChange}
            placeholder="Link to blog"
          />
          <input
            type="text"
            name="title"
            value={title}
            onChange={onChange}
            placeholder="Title*"
            required
          />
          <input
            type="text"
            name="topics"
            value={topics}
            onChange={onChange}
            placeholder="Comma separated topics*"
            required
          />
          <textarea
            name="content"
            value={content}
            onChange={onChange}
            placeholder="Paste your blog here*"
            required
          />
          <input
            type="text"
            name="note"
            value={note}
            onChange={onChange}
            placeholder="Anything else you want us to know?"
          />

          {/* <b>Upload File. We accept .md , .docx , .html</b>
        <FileUpload onFileUpload={fileUpload} removeFile={removeFile} /> */}

          <button>Submit</button>
        </form>
      )}
    </Layout>
  )
}

export const data = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
